import type {ActionReducerMapBuilder} from '@reduxjs/toolkit';
import type {EventHashedID, EventsReduxState, EventTimeFilterMap, FetchEventsFromServerParams} from '@Components/events/events.types';
import {UserEventCreationPermissionState} from '@Components/events/events.types';
import {deleteEvent, fetchDoesUserNeedEventCreationUpsell, getEventsList, hideEmbedEventsScheduleBanner, loadEventsPageMetadata, toggleAllowRegistrationsForEvent, toggleShareWithTeam} from '@Components/events/events-thunk';
import {LoadingStates} from '@Utils/loading.util';
import {EVENTS_LAZY_BATCH_SIZE, getEventsLazyDataBasedOnAppliedFilters} from '@Libraries/events.library';
import {objectArrayToHashmap, objectsEqual, removeItem} from '@Utils/array.util';

export const eventsExtraReducers = (builder: ActionReducerMapBuilder<NoInfer<EventsReduxState>>): void => {
  builder.addCase(fetchDoesUserNeedEventCreationUpsell.fulfilled, (state, {payload}) => {
    state.userEventCreationPermissionState = payload ? UserEventCreationPermissionState.NEEDS_UPSELL : UserEventCreationPermissionState.CAN_CREATE;
  });

  builder.addCase(getEventsList.pending, (state): void => {
    const lazyData = getEventsLazyDataBasedOnAppliedFilters(state, state.eventsViewType, state.filters);
    lazyData.loadingState = LoadingStates.LOADING;
  });

  builder.addCase(getEventsList.fulfilled, (state, {meta, payload}) => {
    if (isAjaxResponseOutdated(state, meta.arg)) {
      return;
    }

    const lazyData = getEventsLazyDataBasedOnAppliedFilters(state, state.eventsViewType, state.filters);
    lazyData.loadingState = LoadingStates.LOADED;
    const ids = payload.map((event) => {
      return event.hashedID;
    });

    lazyData.ids = lazyData.ids.concat(ids);
    lazyData.loadMore = ids.length >= EVENTS_LAZY_BATCH_SIZE;
    state.allEvents = {...state.allEvents, ...objectArrayToHashmap(payload, 'hashedID')};
  });

  builder.addCase(toggleShareWithTeam.fulfilled, (state, {meta}) => {
    const event = state.allEvents[meta.arg];
    if (event) {
      event.isShared = !event.isShared;
    }
  });

  builder.addCase(toggleAllowRegistrationsForEvent.fulfilled, (state, {meta, payload}) => {
    const event = state.allEvents[meta.arg];
    if (event) {
      event.allowRegistrations = payload;
    }
  });

  builder.addCase(deleteEvent.fulfilled, (state, {meta}) => {
    removeEventFromStore(state, meta.arg);
  });

  builder.addCase(loadEventsPageMetadata.pending, (state) => {
    state.pageMetaDataLoadingState = LoadingStates.LOADING;
  });

  builder.addCase(loadEventsPageMetadata.rejected, (state) => {
    state.pageMetaDataLoadingState = LoadingStates.LOADED;
  });

  builder.addCase(loadEventsPageMetadata.fulfilled, (state, {payload}) => {
    state.userEventCreationPermissionState = payload.doShowUpsell ? UserEventCreationPermissionState.NEEDS_UPSELL : UserEventCreationPermissionState.CAN_CREATE;
    state.doShowEmbedBanner = payload.doShowEmbedBanner;
    state.pageMetaDataLoadingState = LoadingStates.LOADED;
  });

  builder.addCase(hideEmbedEventsScheduleBanner.pending, (state) => {
    state.doShowEmbedBanner = false;
  })
};

const isAjaxResponseOutdated = (state: EventsReduxState, ajaxParams: FetchEventsFromServerParams): boolean => {
  return (
    state.eventsViewType !== ajaxParams.eventsViewType || !areStatusFiltersSame(state.filters.timeFilter, ajaxParams.timeFilters) || ajaxParams.sort !== state.filters.sortType
  );
};

const areStatusFiltersSame = (statusFilters1: EventTimeFilterMap, statusFilter2: EventTimeFilterMap): boolean => {
  return objectsEqual(statusFilters1, statusFilter2);
};

export const removeEventFromStore = (state: EventsReduxState, eventHashedID: EventHashedID): void => {
  removeItem(state.eventsListDefaultLazyData.ids, eventHashedID);
  removeItem(state.eventsListFilteredLazyData.ids, eventHashedID);
  delete state.allEvents[eventHashedID];
}
