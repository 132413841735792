import type {ReactElement, Ref} from 'react';
import React from 'react';
import {IconSize} from '@Components/icon-v2/icon.types';
import {DROPDOWN_TEXT_TYPE, DropdownText} from '@Components/dropdown-v2/components/dropdown-text';
import styles from './dropdown-ghost-select-box.module.scss';
import {Icon} from '../../../icon-v2';
import {CircularProgressLoader, LOADER_COLOR, LOADER_SIZE} from '../../../circular-progress-loader';
import type {BaseDropdownSelectBox} from '../../dropdown.types';
import {DROPDOWN_SIZE} from '../../dropdown.types';

type DropdownGhostSelectBoxProps = BaseDropdownSelectBox;

export const DropdownGhostSelectBox = React.forwardRef(
  (
    {
      id = '',
      text = '',
      icon = '',
      svg = undefined,
      isDisabled = false,
      isOpened = false,
      isLoading = false,
      className = '',
      textClassName = '',
      isBold = false,
      size = DROPDOWN_SIZE.SMALL,
      onClick = (): void => {},
      openedClassName = '',
    }: DropdownGhostSelectBoxProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const getIconSize = (): IconSize => {
      switch (size) {
        case DROPDOWN_SIZE.XSMALL:
          return IconSize.SIZE_ICON_16;
        case DROPDOWN_SIZE.SMALL:
          return IconSize.SIZE_ICON_20;
        case DROPDOWN_SIZE.MEDIUM:
          return IconSize.SIZE_ICON_24;
        default:
          return IconSize.SIZE_ICON_16;
      }
    };

    const onClickHandler = (): void => {
      if (isDisabled) {
        return;
      }
      if (onClick) {
        onClick(id);
      }
    };

    const getPaddingClasses = (): string => {
      switch (size) {
        case DROPDOWN_SIZE.XSMALL:
          return styles.selectorXSmallPadding;
        case DROPDOWN_SIZE.SMALL:
          return styles.selectorSmallPadding;
        case DROPDOWN_SIZE.MEDIUM:
          return styles.selectorMediumPadding;
        default:
          return '';
      }
    };

    const getSelectBoxClasses = (): string => {
      const classes = [styles.selectBox, getPaddingClasses(), 'flex-row-justify-between', 'flex-items-center', 'hover-transition-all', className];

      if (isOpened) {
        classes.push(styles.opened);
        classes.push(openedClassName);
      }

      if (isDisabled) {
        classes.push(styles.disabled);
      }

      return classes.join(' ');
    };

    const getMarginForSelectorText = (): string => {
      switch (size) {
        case DROPDOWN_SIZE.SMALL:
          return styles.rightMarginSmall;
        case DROPDOWN_SIZE.MEDIUM:
          return 'spacing-m-r-2';
        default:
          return '';
      }
    };

    const getDropdownCarets = (): ReactElement => {
      if (isLoading) {
        return <CircularProgressLoader color={LOADER_COLOR.DARK} size={LOADER_SIZE.SMALL} />;
      }

      return (
        <div className="flexbox">
          {isOpened ? <Icon icon="icon-caret-up" size={getIconSize()} /> : <Icon icon="icon-caret-down" size={getIconSize()} className={`${isDisabled ? 'content-disabled' : ''}`} />}
        </div>
      );
    };

    return (
      <div ref={ref} id={id} className={`${getSelectBoxClasses()}`} onClick={onClickHandler}>
        <DropdownText
          className={`${textClassName} ${getMarginForSelectorText()}`}
          text={text}
          size={size}
          icon={icon}
          svg={svg}
          type={DROPDOWN_TEXT_TYPE.SELECTOR_TEXT}
          isDisabled={isDisabled}
          isBold={isBold}
        />
        {getDropdownCarets()}
      </div>
    );
  }
);
