import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
  ContentPlannerEntries,
  ContentPlannerThunkParams,
  CustomEventThunkParams,
  EditCustomEventThunkParams,
  EventEntry,
  PlannerRegionsAndReligions,
  UserCalendarPreferences,
  UserCalendarPreferencesServerResponse} from '@Components/content-planner/content-planner.types';
import {
  REGIONS_AND_RELIGIONS_CACHE_KEY
} from '@Components/content-planner/content-planner.types';

export const getEntriesForContentPlanner = createAsyncThunk<ContentPlannerEntries, ContentPlannerThunkParams>(
  'getEntriesForContentPlanner',
  async ({fromTimestamp, toTimestamp, lazyLoadingInfo, calendarEntriesViewType}): Promise<ContentPlannerEntries> => {
    return window.PMW.readLocal('planner/getPlannerEntriesForDates', {
      fromTimestamp,
      toTimestamp,
      lazyLoadingInfo,
      calendarEntriesViewType
    });
  }
);

export const getRegionsAndReligionsForPlannerEvents = createAsyncThunk<PlannerRegionsAndReligions>(
  'getRegionsAndReligionsForPlannerEvents',
  async (): Promise<PlannerRegionsAndReligions> => {
    let regionsAndReligions = window.PMW.getCacheItem(REGIONS_AND_RELIGIONS_CACHE_KEY) as PlannerRegionsAndReligions;

    if (!regionsAndReligions) {
      regionsAndReligions = (await window.PMW.readLocal('planner/getRegionsAndReligions')) as PlannerRegionsAndReligions;
      window.PMW.setCacheItem(REGIONS_AND_RELIGIONS_CACHE_KEY);
    }

    return regionsAndReligions;
  }
);

export const getUserCalendarPreferences = createAsyncThunk<UserCalendarPreferencesServerResponse>(
  'getUserCalendarPreferences',
  async (): Promise<UserCalendarPreferencesServerResponse> => {
    return window.PMW.readLocal('planner/getUserCalendarPreferences');
  }
);

export const saveUserCalendarPreferences = createAsyncThunk<void, UserCalendarPreferences>('saveUserCalendarPreferences', async (preferences): Promise<void> => {
  return window.PMW.writeLocal('planner/saveUserCalendarPreferences', preferences);
});

export const createCustomEvent = createAsyncThunk<EventEntry, CustomEventThunkParams>('createCustomEvent', async ({eventData}): Promise<EventEntry> => {
  return window.PMW.writeLocal('planner/createCustomEvent', {
    timestamp: eventData.timestamp,
    title: eventData.title,
    description: eventData.description,
  });
});

export const editCustomEvent = createAsyncThunk<void, EditCustomEventThunkParams>('editCustomEvent', async ({eventData}): Promise<void> => {
  return window.PMW.writeLocal('planner/editCustomEvent', {
    eventId: eventData.id,
    timestamp: eventData.timestamp,
    title: eventData.title,
    description: eventData.description,
  });
});

export const deleteCustomEvent = createAsyncThunk<void, CustomEventThunkParams>('deleteCustomEvent', async ({eventData}): Promise<void> => {
  return window.PMW.writeLocal('planner/deleteCustomEvent', {
    eventId: eventData.id,
  });
});
