import type {ActionReducerMapBuilder} from '@reduxjs/toolkit';
import type {EventsHomePageMetaBackendResponse, EventsHomeReduxState, UserEventsScheduleVO} from '@Components/mystuff-events-home/mystuff-events-home.types';
import {getEventsScheduleInfoForUser} from '@Components/mystuff-events-home/mystuff-events-home-thunk';
import {LoadingStates} from '@Utils/loading.util';
import {PMW_COLORS_PRIMARY} from '@Utils/color.util';
import {EventsHomePageLayout} from '@Components/mystuff-events-home/mystuff-events-home.types';
import type {SocialMediaProfileLinksMap} from '@Components/social-media-profile-links/social-media-profile-links.types';
import {SocialMediaProfile} from '@Components/social-media-profile-links/social-media-profile-links.types';

export const mystuffEventsHomeExtraReducers = (builder: ActionReducerMapBuilder<NoInfer<EventsHomeReduxState>>): void => {
  builder.addCase(getEventsScheduleInfoForUser.pending, (state) => {
    state.loadingState = LoadingStates.LOADING;
  });

  builder.addCase(getEventsScheduleInfoForUser.fulfilled, (state, {payload}) => {
    const userEventsScheduleVO = payload.userEventsSchedule;
    state.eventsHomeHashedId = userEventsScheduleVO.hashedID;
    state.pageDetailsFormData = {
      title: userEventsScheduleVO.title,
      description: userEventsScheduleVO.description ?? '',
      doShowPastEvents: userEventsScheduleVO.doShowPastEvents ?? true,
      doRemoveWatermark: userEventsScheduleVO.doRemoveWatermark ?? false,
      imageURL: userEventsScheduleVO.profilePictureURL,
      coverPhotoURL: userEventsScheduleVO.coverPhotoURL,
      fontFamily: userEventsScheduleVO.fontFamily ?? 'RakkasRegular',
      color: userEventsScheduleVO.primaryColor ?? PMW_COLORS_PRIMARY.PRIMARY_100,
      eventsHomeLayoutPreference: userEventsScheduleVO.viewType ? (userEventsScheduleVO.viewType as EventsHomePageLayout) : EventsHomePageLayout.LIST,
    };

    state.contactDetailsFormData = {
      email: userEventsScheduleVO.email,
      phoneNumber: userEventsScheduleVO.phoneNumber ?? '',
      socialMediaProfilesInput: getSocialProfileLinksFromPayload(userEventsScheduleVO),
    };
    state.recentEmailsUsed = payload.emailSuggestions;
    state.recentPhoneNumbersUsed = payload.phoneNumberSuggestions;
    state.loadingState = LoadingStates.LOADED;
  });

  builder.addCase(getEventsScheduleInfoForUser.rejected, (state) => {
    state.loadingState = LoadingStates.LOADED;
  });
};

// codeReviewShahrukh: review and finalise - from jibran: try using initSocialMediaProfileLinks
const getSocialProfileLinksFromPayload = (payload: UserEventsScheduleVO): SocialMediaProfileLinksMap => {
  return {
    [SocialMediaProfile.FACEBOOK]: '',
    [SocialMediaProfile.INSTAGRAM]: '',
    [SocialMediaProfile.LINKEDIN]: '',
    [SocialMediaProfile.X]: '',
    [SocialMediaProfile.PINTEREST]: '',
    [SocialMediaProfile.YOUTUBE]: '',
    [SocialMediaProfile.TIKTOK]: '',
    ...(payload.socialProfileLinks ?? {}),
  };
};
