import type {TimeZone} from 'timezones-list';

export const getTimeZoneLabelText = (timeZone: TimeZone): string => {
  const tzCodeText = getTzCodeText(timeZone.tzCode);
  let utcText = timeZone.label.split(' ')[1];
  utcText = utcText.substring(1, utcText.length - 1);
  return `${tzCodeText} · ${utcText}`;
};

/**
 * Replaces "/" with " - "
 * Replaces "_" with " "
 * @param tzCode
 */
export const getTzCodeText = (tzCode: string): string => {
  return tzCode.replace(/\/|_/g, (match) => {
    return match === '/' ? ' - ' : ' ';
  });
};

export const getUTCOffsetMinutesFromTimeZone = (timeZone: TimeZone): number => {
  const timeString = timeZone.utc;
  const [sign, time] = [timeString[0], timeString.slice(1)];
  const [hours, minutes] = time.split(':').map(Number);
  const totalMinutes = hours * 60 + minutes;
  return sign === '-' ? -totalMinutes : totalMinutes;
};
