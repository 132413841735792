import type {ReactElement} from 'react';
import React from 'react';
import {POPUP_TOGGLE_ITEM_CLASS} from '@Components/popup-menu/popup-menu';
import {Text, TextSize} from '@Components/text';
import {ToggleSwitch, ToggleSwitchV2Size} from '@Components/toggle-switch-v2';
import styles from './popup-menu-toggle-item.module.scss';

interface PopupMenuToggleItemProps {
  text: string;
  isChecked: boolean;
  onChange: VoidFunction;
  isDisabled?: boolean;
  isLoading?: boolean;
  hasTopSeparator?: boolean;
  isListItem?: boolean;
  hasPremiumUpselling?: boolean;
}

export function PopupMenuToggleItem({text, isChecked, onChange, isDisabled = false, isLoading = false, hasTopSeparator = true, isListItem = true, hasPremiumUpselling = false}: PopupMenuToggleItemProps): ReactElement {
  const getTopSeparator = (): ReactElement | null => {
    if (!hasTopSeparator) {
      return null;
    }

    return <div className="border-s-standard radius-8 spacing-m-r-2 spacing-m-l-2 _no-border-bottom _no-border-right _no-border-left" />;
  };

  const getInnerContent = (): ReactElement => {
    return (
        <div className="flex-v-row _full-width">
          {getTopSeparator()}
          <div className={`flex-h-row flex-align-items-center flex-content-between spacing-m-4 ${POPUP_TOGGLE_ITEM_CLASS}`}>
            <Text className="_text-nowrap spacing-m-r-6" val={text} size={TextSize.XSMALL} />
            <ToggleSwitch className={styles.popupToggleSwitch} isDisabled={isDisabled || hasPremiumUpselling} isLoading={isLoading} isChecked={isChecked} onChange={onChange} size={ToggleSwitchV2Size.SMALL} icon={hasPremiumUpselling ? 'icon-crown': ''} iconClassName={hasPremiumUpselling ? styles.premiumIcon : ''} />
          </div>
        </div>
    );
  }

  return isListItem ? (
      <li className="flexbox _unpadded _unmargin _full-width">
        {getInnerContent()}
      </li>
  ): getInnerContent();
}
