import type {Weekdays} from '@Components/event-wizard/week-day-selectors/week-day-selectors.types';

export interface MonthDaySelectorsProps {
  isMobile?: boolean;
}

export interface RecurringMonth {
  weekdayNum: string;
  weekday: Weekdays;
}

export const numWeekDropdownID = 'numWeekDropdownID';
export const dayOfWeekDropdownID = 'dayOfWeekDropdownID';

export const WEEKDAY_NUMS = ['1st', '2nd', '3rd', '4th', 'last'];
