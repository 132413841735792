export interface EventWizardDateTimePanelProps {
  // add props here
}

export const EVENT_WIZARD_DATE_TIME_PANEL_ID = 'event-wizard-date-time-panel';

export enum DateTimeMobilePanel {
  DEFAULT = 'DEFAULT',
  RECURRING_TYPE_SELECTOR = 'RECURRING_TYPE_SELECTOR',
  TIMEZONE_SELECTOR = 'TIMEZONE_SELECTOR',
  START_DATE_CALENDAR = 'START_DATE_CALENDAR',
  END_DATE_CALENDAR = 'END_DATE_CALENDAR',
  START_TIME_INPUT = 'START_TIME_INPUT',
  END_TIME_INPUT = 'END_TIME_INPUT',
  RECURRING_MONTH_WEEKDAY_NUM_SELECTOR = 'RECURRING_MONTH_WEEKDAY_NUM_SELECTOR',
  RECURRING_MONTH_WEEKDAY_SELECTOR = 'RECURRING_MONTH_WEEKDAY_SELECTOR',
  RECURRING_ENDS_ON_SELECTOR = 'RECURRING_ENDS_ON_SELECTOR',
}
