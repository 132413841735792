import {createAsyncThunk} from "@reduxjs/toolkit";
import type {EventHashedID, EventVO, FetchEventsFromServerParams} from "@Components/events/events.types";
import {EventsPageMetaDataResponse} from "@Components/events/events.types";


export const fetchDoesUserNeedEventCreationUpsell = createAsyncThunk<boolean>('fetchDoesUserNeedEventCreationUpsell', async () => {
    return window.PMW.readLocal('event/doesUserNeedUpsellForCreatingEvent');
});

export const getEventsList = createAsyncThunk<EventVO[], FetchEventsFromServerParams>('getEventsList', async (serverData) => {
    return window.PMW.readLocal('event/getUserEventsForMyStuff', serverData);
})

export const toggleShareWithTeam = createAsyncThunk('toggleShareWithTeam', async (eventHashedId: EventHashedID) => {
    return window.PMW.writeLocal('event/toggleEventTeamShare', {
        event_hid: eventHashedId
    })
});

export const toggleAllowRegistrationsForEvent = createAsyncThunk<boolean, EventHashedID>('toggleAllowRegistrationsForEvent', async (eventHashedId: EventHashedID) => {
    return window.PMW.writeLocal('event/toggleAllowRegistrationsForEvent', {
        event_hid: eventHashedId
    });
});

export const deleteEvent = createAsyncThunk('deleteEvent', async (eventHashedId: EventHashedID) => {
    return window.PMW.writeLocal('event/deleteEvent', {
        event_hid: eventHashedId
    })
});

export const loadEventsPageMetadata = createAsyncThunk<EventsPageMetaDataResponse>('loadEventsPageMetadata', async () => {
    return window.PMW.writeLocal('event/loadEventsPageMetadata');
})

export const hideEmbedEventsScheduleBanner = createAsyncThunk('hideEmbedEventsScheduleBanner', async (): Promise<void> => {
    return window.PMW.writeLocal('event/hideEmbedEventsScheduleBanner');
})