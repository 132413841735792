import type {ActionReducerMapBuilder} from '@reduxjs/toolkit/src/mapBuilders';
import type {NoInfer} from '@reduxjs/toolkit/src/tsHelpers';
import {
  createCustomEvent,
  deleteCustomEvent,
  editCustomEvent,
  getEntriesForContentPlanner,
  getRegionsAndReligionsForPlannerEvents,
  getUserCalendarPreferences,
} from '@Components/content-planner/content-planner-thunk';
import {updateEntries} from '@Components/content-planner/content-planner-reducer';
import {areDatesEqual, getDateFromUnixTimestamp, getDatesBetween, getNextMonth, getPreviousMonth, getUnixTimestamp, isBetweenDates} from '@Utils/date.util';
import {
  getEmptyBaseEventEntry,
  getEmptyEntriesObject,
  getEntriesMapKey,
  getFormattedDateForEntryType,
  getFormattedDateForEntryTypeFromTimestamp,
  getIdsFromAreaFiltersList,
  isDateInCalendar,
  isNonLunarEvent,
} from '@Libraries/content-planner-library';
import type {EventVO} from '@Components/events/events.types';
import type {EmailCampaign} from "@Components/email-marketing-campaigns/email-marketing-campaigns.types";
import type {
  AreaFilterIds,
  ContentPlannerEntries,
  ContentPlannerLazyLoadingData,
  ContentPlannerState,
  CustomEventThunkParams,
  EditCustomEventThunkParams,
  EntriesMapForPlannerCell,
  EventEntry,
  ModifyPlannerEntryCellParams,
  ScheduledEntry,
} from './content-planner.types';
import {PlannerEntryCellOperation, SocialGlobalEventFilterOption} from './content-planner.types';
import type {SocialPost} from "@Components/social-media/post.vo";

export const contentPlannerExtraReducers = (builder: ActionReducerMapBuilder<NoInfer<ContentPlannerState>>): void => {
  builder.addCase(getEntriesForContentPlanner.fulfilled, (state, {payload, meta}) => {
    const dateFrom: string = meta.arg.fromTimestamp.toString();
    const dateTo: string = meta.arg.toTimestamp.toString();
    const entriesMapKey: string = dateFrom + dateTo;
    payload.internalEventEntries = getEventEntriesWithAdjustedTimestamps(meta.arg.toTimestamp, payload.internalEventEntries);
    updateEntries(state, getEntriesFromJson(payload), entriesMapKey);
  });

  builder.addCase(getRegionsAndReligionsForPlannerEvents.fulfilled, (state, {payload}) => {
    state.regions = payload.regions;
    state.religions = payload.religions;
    populateRegionalAndReligiousFilters(state);
  });

  builder.addCase(getUserCalendarPreferences.fulfilled, (state, {payload}) => {
    const {userCalendarPreferences} = payload;
    if (!userCalendarPreferences) {
      return;
    }

    state.contentPlannerFilters.socialGlobalEventFilters = {
      [SocialGlobalEventFilterOption.GLOBAL]: userCalendarPreferences.showGlobalEvents,
      [SocialGlobalEventFilterOption.SOCIAL_MEDIA]: userCalendarPreferences.showSocialEvents,
    };

    populateRegionalAndReligiousFilters(
      state,
      convertIdsToStringArray(userCalendarPreferences.selectedRegions),
      convertIdsToStringArray(userCalendarPreferences.selectedReligions)
    );
  });

  builder.addCase(createCustomEvent.fulfilled, (state, {payload}) => {
    modifyEntriesInContentPlanner({
      operation: PlannerEntryCellOperation.ADD,
      date: getDateFromUnixTimestamp(payload.timestamp),
      eventEntry: payload,
      state,
    });

    state.customEventFormData = getEmptyBaseEventEntry();
  });

  builder.addCase(editCustomEvent.fulfilled, (state, {meta}) => {
    updateEventEntry(state, meta.arg);
  });

  builder.addCase(deleteCustomEvent.fulfilled, (state, {meta}) => {
    onDeleteCustomEventSuccess(state, meta.arg);
  });
};

const onDeleteCustomEventSuccess = (state: ContentPlannerState, thunkParams: CustomEventThunkParams): void => {
  const {eventData, entriesMapKey} = thunkParams;
  const entriesForPlannerCell = getEntriesMapForKey(state, entriesMapKey);

  if (!entriesForPlannerCell) {
    return;
  }

  const event = getEventFromId(entriesForPlannerCell, eventData.id, eventData.timestamp);
  modifyEntriesInContentPlanner({
    operation: PlannerEntryCellOperation.DELETE,
    date: getDateFromUnixTimestamp(eventData.timestamp),
    eventEntry: event,
    state,
  });
};

function getEntriesFromJson(plannerEntries: ContentPlannerEntries): EntriesMapForPlannerCell {
  const entries: EntriesMapForPlannerCell = {
    entriesMappedByDate: {},
    areEntriesPopulated: true,
  };

  populateScheduledEntries(entries, plannerEntries.scheduledEntries);
  populateEventEntries(entries, plannerEntries.internalEventEntries);
  populateUserEventEntries(entries, plannerEntries.userEventEntries);
  populateSocialPostEntries(entries, plannerEntries.socialPostEntries);
  populateEmailCampaignEntries(entries, plannerEntries.emailCampaignEntries);
  populateLazyLoadingData(entries, plannerEntries.lazyLoadingData);
  updateAreEntriesPopulatedForLazyLoad(entries, plannerEntries.lazyLoadingData);

  return entries;
}

const populateEmailCampaignEntries = (entriesMapForPlannerCell: EntriesMapForPlannerCell, emailCampaignEntries: EmailCampaign[]): void => {
  let formattedDate = '';

  emailCampaignEntries.forEach((entry) => {
    formattedDate = getFormattedDateForEntryTypeFromTimestamp(entry.publishOn);
    getEntriesForDate(entriesMapForPlannerCell, formattedDate).emailCampaignEntries.push(entry);
  });
}
const populateSocialPostEntries = (entriesMapForPlannerCell: EntriesMapForPlannerCell, socialPostEntries: SocialPost[]): void => {
  let formattedDate = '';

  socialPostEntries.forEach((entry) => {
    const entryTimestamp = entry.publishOn ?? (entry.createdOn ?? getUnixTimestamp(new Date()));
    formattedDate = getFormattedDateForEntryTypeFromTimestamp(entryTimestamp);
    getEntriesForDate(entriesMapForPlannerCell, formattedDate).socialPostEntries.push(entry);
  });
}

const populateScheduledEntries = (entriesMapForPlannerCell: EntriesMapForPlannerCell, scheduledEntries: ScheduledEntry[]): void => {
  let formattedDate: string;

  scheduledEntries.forEach((entry) => {
    formattedDate = getFormattedDateForEntryTypeFromTimestamp(entry.timestamp);
    getEntriesForDate(entriesMapForPlannerCell, formattedDate).scheduledEntries.push(entry);
  });
};

const populateEventEntries = (entriesMapForPlannerCell: EntriesMapForPlannerCell, eventEntries: EventEntry[]): void => {
  let entryDate: Date;

  eventEntries.forEach((entry) => {
    entryDate = getDateFromUnixTimestamp(entry.timestamp);
    populateEventEntry(entriesMapForPlannerCell, entryDate, entry);
    if (isNonLunarEvent(entry)) {
      populateNonLunarEventEntries(entriesMapForPlannerCell, entryDate, entry);
    }
  });
};

const populateUserEventEntries = (entriesMapForPlannerCell: EntriesMapForPlannerCell, userEventEntries: EventVO[]): void => {
  userEventEntries.forEach((entry) => {
    populateUserEventEntryForAllDates(entriesMapForPlannerCell, entry);
  });
};

const populateUserEventEntryForAllDates = (entriesMapForPlannerCell: EntriesMapForPlannerCell, userEvent: EventVO): void => {
  const entryStartDate = getDateFromUnixTimestamp(userEvent.startDateTimestamp);
  const entryEndDate = getDateFromUnixTimestamp(userEvent.endDateTimestamp);
  const datesToPopulateEntryFor = areDatesEqual(entryStartDate, entryEndDate) ? [entryStartDate]: getDatesBetween(entryStartDate, entryEndDate);
  datesToPopulateEntryFor.forEach(date => {
    populateUserEventEntry(entriesMapForPlannerCell, date, userEvent);
  })
}

const populateLazyLoadingData = (entriesMapForPlannerCell: EntriesMapForPlannerCell, lazyLoadingData: ContentPlannerLazyLoadingData | null | undefined): void => {
  if (lazyLoadingData) {
    Object.assign(entriesMapForPlannerCell, {lazyLoadingData});
  }
};

const updateAreEntriesPopulatedForLazyLoad = (entriesMapForPlannerCell: EntriesMapForPlannerCell, lazyLoadingData: ContentPlannerLazyLoadingData | null | undefined): void => {
  if (!lazyLoadingData) {
    return;
  }

  const lazyLoadInfo = lazyLoadingData.entryTypeLazyLoadData;
  if (!lazyLoadInfo) {
    return;
  }

  Object.keys(lazyLoadInfo).forEach((key) => {
    const entryData = lazyLoadInfo[key];
    if (entryData.areMoreEntriesNeeded) {
      Object.assign(entriesMapForPlannerCell, {areEntriesPopulated: false});
    }
  });
};

const populateNonLunarEventEntries = (entriesMapForPlannerCell: EntriesMapForPlannerCell, entryDate: Date, entry: EventEntry): void => {
  const prevYearDate = new Date(entryDate);
  const nextYearDate = new Date(entryDate);
  prevYearDate.setFullYear(entryDate.getFullYear() - 1);
  nextYearDate.setFullYear(entryDate.getFullYear() + 1);
  populateEventEntry(entriesMapForPlannerCell, prevYearDate, entry);
  populateEventEntry(entriesMapForPlannerCell, nextYearDate, entry);
};

const populateEventEntry = (entriesMapForPlannerCell: EntriesMapForPlannerCell, entryDate: Date, entry: EventEntry): void => {
  const formattedDate = getFormattedDateForEntryType(entryDate);
  getEntriesForDate(entriesMapForPlannerCell, formattedDate).internalEventEntries.push(entry);
};

const populateUserEventEntry = (entriesMapForPlannerCell: EntriesMapForPlannerCell, entryDate: Date, entry: EventVO): void => {
  const formattedDate = getFormattedDateForEntryType(entryDate);
  getEntriesForDate(entriesMapForPlannerCell, formattedDate).userEventEntries.push(entry);
};

const populateRegionalAndReligiousFilters = (state: ContentPlannerState, preSelectedRegions: AreaFilterIds = [], preSelectedReligions: AreaFilterIds = []): void => {
  state.contentPlannerFilters.eventRegionFilters = {};
  state.contentPlannerFilters.eventReligionFilters = {};
  initializeRegionalFilters(state, preSelectedRegions);
  initializeReligiousFilters(state, preSelectedReligions);
};

const initializeRegionalFilters = (state: ContentPlannerState, preSelectedFilters: AreaFilterIds): void => {
  const regionIds = getIdsFromAreaFiltersList(state.regions);
  regionIds.forEach((id) => {
    state.contentPlannerFilters.eventRegionFilters[id] = preSelectedFilters.includes(id);
  });
};

const initializeReligiousFilters = (state: ContentPlannerState, preSelectedFilters: AreaFilterIds): void => {
  const religionIds = getIdsFromAreaFiltersList(state.religions);
  religionIds.forEach((id) => {
    state.contentPlannerFilters.eventReligionFilters[id] = preSelectedFilters.includes(id);
  });
};

export const getEntriesMapForKey = (state: ContentPlannerState, entriesMapKey: string): EntriesMapForPlannerCell | undefined => {
  return state.entriesMap[entriesMapKey];
};

const getNonNullEntriesMap = (state: ContentPlannerState, entriesMapKey: string): EntriesMapForPlannerCell => {
  if (getEntriesMapForKey(state, entriesMapKey) !== undefined) {
    return state.entriesMap[entriesMapKey];
  }

  const defaultEntries: EntriesMapForPlannerCell = {
    entriesMappedByDate: {},
    areEntriesPopulated: false,
  };

  updateEntries(state, defaultEntries, entriesMapKey);
  return state.entriesMap[entriesMapKey];
};

const updateEventEntry = (state: ContentPlannerState, thunkParams: EditCustomEventThunkParams): void => {
  const {eventData, entriesMapKey, originalTimestamp} = thunkParams;
  const newDate = getDateFromUnixTimestamp(eventData.timestamp);
  const entriesForKey = getNonNullEntriesMap(state, entriesMapKey);
  const oldDate = getDateFromUnixTimestamp(originalTimestamp);
  const event = getEventFromId(entriesForKey, eventData.id, originalTimestamp);

  if (!event) {
    console.error('Error fetching event');
    return;
  }

  event.title = eventData.title;
  event.description = eventData.description;
  event.timestamp = eventData.timestamp;

  if (areDatesEqual(newDate, oldDate)) {
    return;
  }

  modifyEntriesInContentPlanner({
    operation: PlannerEntryCellOperation.DELETE,
    date: oldDate,
    eventEntry: event,
    state,
  });

  modifyEntriesInContentPlanner({
    operation: PlannerEntryCellOperation.ADD,
    date: newDate,
    eventEntry: event,
    state,
  });
};

const getEventFromId = (entriesMap: EntriesMapForPlannerCell, id: number, timestamp: number): EventEntry | undefined => {
  const formattedDate = getFormattedDateForEntryTypeFromTimestamp(timestamp);
  const entriesForDate = getEntriesForDate(entriesMap, formattedDate);

  return entriesForDate.internalEventEntries.find((eventEntry) => {
    return eventEntry.id === id;
  });
};

const getEntriesForDate = (entriesMap: EntriesMapForPlannerCell, formattedDate: string): ContentPlannerEntries => {
  if (typeof entriesMap.entriesMappedByDate[formattedDate] === 'undefined') {
    entriesMap.entriesMappedByDate[formattedDate] = getEmptyEntriesObject();
  }
  return entriesMap.entriesMappedByDate[formattedDate];
};

export const modifyEntriesInContentPlanner = (params: ModifyPlannerEntryCellParams): void => {
  const nextMonth = getNextMonth(params.date);
  const prevMonth = getPreviousMonth(params.date);

  modifyCalendarCell(params, getEntriesMapKey(params.date));

  if (isDateInCalendar(nextMonth, params.date)) {
    modifyCalendarCell(params, getEntriesMapKey(nextMonth));
  }

  if (isDateInCalendar(prevMonth, params.date)) {
    modifyCalendarCell(params, getEntriesMapKey(prevMonth));
  }
};

const modifyCalendarCell = (params: ModifyPlannerEntryCellParams, entriesMapKey: string): void => {
  const {state, scheduledEntry, eventEntry, operation} = params;
  const entriesMapForPlannerCell = getEntriesMapForKey(state, entriesMapKey);
  const isAdding = operation === PlannerEntryCellOperation.ADD;
  const isDeleting = operation === PlannerEntryCellOperation.DELETE;
  if (entriesMapForPlannerCell === undefined) {
    return;
  }

  if (isAdding && scheduledEntry) {
    populateScheduledEntries(entriesMapForPlannerCell, [scheduledEntry]);
  }

  if (isAdding && eventEntry) {
    populateEventEntries(entriesMapForPlannerCell, [eventEntry]);
  }

  if (isDeleting && scheduledEntry) {
    removeScheduledEntry(entriesMapForPlannerCell, scheduledEntry);
  }

  if (isDeleting && eventEntry) {
    removeEventEntry(entriesMapForPlannerCell, eventEntry);
  }
};

const removeScheduledEntry = (entriesMapForPlannerCell: EntriesMapForPlannerCell, scheduledEntry: ScheduledEntry): void => {
  const {entriesMappedByDate} = entriesMapForPlannerCell;

  for (const entries of Object.values(entriesMappedByDate)) {
    entries.scheduledEntries = entries.scheduledEntries.filter((entry) => {
      return entry.id !== scheduledEntry.id;
    });
  }
};

const removeEventEntry = (entriesMapForPlannerCell: EntriesMapForPlannerCell, entryToRemove: EventEntry): void => {
  const {entriesMappedByDate} = entriesMapForPlannerCell;

  for (const entries of Object.values(entriesMappedByDate)) {
    entries.internalEventEntries = entries.internalEventEntries.filter((entry) => {
      return entry.id !== entryToRemove.id;
    });
  }
};

const convertIdsToStringArray = (arrayToConvert: number[]): string[] => {
  return arrayToConvert.map((id) => {
    return id.toString();
  });
};

/**
 * Adjust the year of the event's timestamp IF it is a non-lunar event
 * since for non-lunar events, the year is irrelevant, but we use the date as a key at the front-end
 * for example:
 * 2023/04/01: [array of events]
 * this structure will fail for later years since a non-lunar event date could be any date, but it needs to be shown in every year at the same date
 * April fools will be shown on 1st April for every year, regardless of 2023, or 2024
 * so if the requested year in the filter is 2025, set the year of the event to 2025 so in the frontend it can be stored as:
 * 2025/04/01
 * @param timestampToAdjustTo
 * @param eventEntries
 */
const getEventEntriesWithAdjustedTimestamps = (timestampToAdjustTo: number, eventEntries: EventEntry[]): EventEntry[] => {
  return eventEntries.map((eventEntry) => {
    if (!isNonLunarEvent(eventEntry)) {
      return eventEntry;
    }

    const eventDate = getDateFromUnixTimestamp(eventEntry.timestamp);
    const newEventDate = adjustYearForEdgeEvents(eventDate, timestampToAdjustTo);

    return {
      ...eventEntry,
      timestamp: getUnixTimestamp(newEventDate),
    };
  });
};

const adjustYearForEdgeEvents = (eventDate: Date, timestampToAdjustTo: number): Date => {
  const JANUARY = 0;
  const FEBRUARY = 1;
  const DECEMBER = 11;

  const toDateMonth = getDateFromUnixTimestamp(timestampToAdjustTo).getMonth();
  const toDateYear = getDateFromUnixTimestamp(timestampToAdjustTo).getFullYear();
  const eventMonth = eventDate.getMonth();

  const isCalendarMonthJanuaryAndEventNotInJanuary = toDateMonth === JANUARY && eventMonth !== JANUARY;
  const isCalendarMonthFebruaryAndEventInDecember = toDateMonth === FEBRUARY && eventMonth === DECEMBER;

  if (isCalendarMonthJanuaryAndEventNotInJanuary || isCalendarMonthFebruaryAndEventInDecember) {
    eventDate.setFullYear(toDateYear - 1);
  } else {
    eventDate.setFullYear(toDateYear);
  }

  return eventDate;
};
